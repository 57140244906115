import { BaseAPIModel, fetcher } from '@weezevent/weezjs-core';
import { ActionModel } from './ActionModel';

const WEBHOOKS_ACTIONS = [
    {
        action: 'bulk_enable',
        label: 'enable',
        method: 'toggle',
        endpoint: 'actions',
        allow_filters: true,
        default_filters: {},
        required_fields: [],
        is_bulk_action: true,
        delete_item: false,
        disabled: { condition: true, bypass_item: true, key: 'active', negate: false }
    },
    {
        action: 'bulk_disable',
        label: 'disable',
        method: 'toggle',
        endpoint: 'actions',
        allow_filters: true,
        default_filters: {},
        required_fields: [],
        is_bulk_action: true,
        delete_item: false,
        disabled: { condition: true, bypass_item: true, key: 'active', negate: true }
    },

    {
        action: 'bulk_delete',
        label: 'archive',
        method: 'archive',
        endpoint: 'actions',
        allow_filters: true,
        default_filters: {},
        required_fields: [],
        is_bulk_action: true,
        delete_item: true,
        disabled: { condition: true, bypass_item: false, key: 'archived', negate: false }
    },
    {
        action: 'retry_failed_calls',
        label: 'retry',
        method: null,
        endpoint: 'executed-calls/actions',
        allow_filters: false,
        default_filters: {
            success: false
        },
        required_fields: ['id'],
        is_bulk_action: false,
        delete_item: false,
        disabled: { condition: false, bypass_item: false, key: null, negate: true }
    }
];

export class Webhook extends ActionModel {
    constructor(args) {
        super(args);
        for (const [key, value] of Object.entries(args)) {
            this[key] = value;
        }
        this.creator = args['creator'];
    }

    fields() {
        return {
            name: '',
            id: { primaryKey: true, readOnly: true }
        };
    }

    get name() {
        return this.creator || this.pk;
    }

    archive(args) {
        return this.destroy({ ...args, id: this.pk });
    }

    toggle(args) {
        const url = this.constructor.getItemUrl({ ...args, id: this.object.id });
        const data = { active: !this.active };
        return fetcher.patch(url, { data });
    }

    static listUrl(params) {
        if (!params.organizationId) {
            return `/webhook/webhook`;
        }
        return `/webhook/organizations/${params.organizationId}/webhook`;
    }

    static getModelActions() {
        return WEBHOOKS_ACTIONS;
    }
}

export class WebhookTypes extends BaseAPIModel {
    static listUrl() {
        return `/webhook/types`;
    }
}

export class WebhookCalls extends BaseAPIModel {
    constructor(args) {
        super(args);
        for (const [key, value] of Object.entries(args)) {
            this[key] = value;
        }
        this.type = this.payload.type;
    }

    fields() {
        return {
            name: '',
            id: { primaryKey: true, readOnly: true }
        };
    }

    static listUrl(params) {
        if (!params.webhookId) {
            throw new Error('[WebhookCalls] Webhook id is mandatory');
        }

        if (!params.organizationId) {
            return `/webhook/webhook/${params.webhookId}/executed-calls`;
        }
        return `/webhook/organizations/${params.organizationId}/webhook/${params.webhookId}/executed-calls`;
    }

    retry(args) {
        const url = `${this.constructor.getItemUrl({ ...args, id: this.object.id })}/retry`;
        return fetcher.get(url);
    }
}
