import { useLocation } from 'react-router-dom';

const useLocationSegment = (organizationId = null) => {
    const location = useLocation();
    const index = organizationId ? 2 : 0;
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const pathSegment = pathSegments[index];
    const pathSegment_singular = pathSegment.slice(0, -1);
    return [pathSegment, pathSegment_singular, location];
};

export default useLocationSegment;
