import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm } from '@weezevent/weezjs-core';
import { usePermissions } from '../../models/user/permissions';
import { Formatter as FormatterModel, Organization } from '../../models';

import { Section, SelectHierarchyLabel, CheckboxLabel, Input, InputLabel, Button, FrameCard, Icon, ButtonDropdown, ToastContext, WeezHeader } from '@weezevent/nacre';
import { JsonEditorField, InfoTextTooltip, LoadingCard, LoadingOptions } from '../../components';
import { FormatterActions } from './components';

import { JSON_DRIVER } from './formatterConstants';

import css from './components/formatter.module.css';

export const Formatter = ({ id = null, organizationId }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const user = usePermissions();
    const toast = React.useContext(ToastContext);
    const [search, setSearch] = React.useState('');
    const [isValidJSON, setIsValidJson] = React.useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = React.useState(false);
    const [form, setForm, setObject] = useForm({
        orgaId: organizationId,
        name: '',
        values: '',
        filter: '',
        template: '',
        driver: ''
    });

    const [formatter, loadingFormatter] = FormatterModel.useApiModel(
        {
            id: id,
            organizationId,
            cache: false,
            allow_cache: false,
            launch: !!id,
            onSuccess: response => {
                const adaptedModel = prepareModelToMatchFormFields(response);
                setObject({ ...adaptedModel });
            }
        },
        [id, organizationId]
    );

    const [organization, loadingOrganization] = Organization.useApiModel(
        {
            id: form?.orgaId,
            cache: false,
            allow_cache: false,
            query: { search, limit: 50 },
            launch: !!form.orgaId
        },
        [search, form.orgaId]
    );

    const [organizations, loadingOrganizations] = Organization.useApiModel(
        {
            cache: false,
            allow_cache: false,
            query: { search, limit: 50 }
        },
        [search]
    );

    const isOriginTemplate = React.useMemo(() => form?.template === '__origin__', [form.template]);

    const options = React.useMemo(() => {
        if (!organizations || loadingOrganizations || (id && loadingOrganization)) {
            return LoadingOptions;
        }
        if (organization) return [organization.toOption()];

        return organizations.map(org => org.toOption());
    }, [organizations, loadingOrganizations, LoadingOptions]);

    const areMandatoryFieldsSet = React.useMemo(() => (organizationId || !!form?.orgaId) && !!form.name && isValidJSON, [form.orgaId, form.name, isValidJSON]);

    const backLink = React.useMemo(() => {
        if (!organizationId) {
            return '/tools/formatters';
        }
        return `/organizations/O${organizationId}/tools/formatters`;
    }, [organizationId]);

    const prepareModelToMatchFormFields = formatter => {
        return {
            orgaId: formatter.organization_id ? Number(formatter.organization_id) : null,
            name: formatter.name,
            values: formatter.get('values'),
            filter: formatter.get('filter'),
            template: formatter.get('template'),
            driver: formatter.get('driver')
        };
    };

    const adaptFormToModel = React.useCallback(() => {
        const { orgaId, name, values, filter, template, driver } = form;
        const jmespathValues = values && `"jmespath_values":${values},`;
        const definition = `{${jmespathValues}"jmespath_filter":"${filter}","template":"${template}"}`;
        const adaptedForm = {
            organization_id: orgaId,
            name: name,
            definition: definition,
            driver: driver ? driver : JSON_DRIVER[0]
        };

        if (id) adaptedForm.id = id;

        return adaptedForm;
    }, [form]);

    const handleSave = React.useCallback(() => {
        setIsSubmitClicked(true);
        const action = id ? 'update' : 'create';
        const adaptedForm = adaptFormToModel(form);
        const newFormatter = new FormatterModel(adaptedForm);
        newFormatter
            .save()
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    history.push(backLink);
                    toast.success(t(`sauron.formatter.toast.${action}.success`));
                }
            })
            .catch(() => {
                toast.error(t(`sauron.formatter.toast.${action}.error`));
                history.push(backLink);
            });
    }, [form, backLink]);

    const handleAction = React.useCallback(
        (_, action) => {
            formatter[action]({ organizationId })
                .then(() => {
                    history.push(backLink);
                    toast.success(t(`sauron.formatter.toast.delete.success`));
                })
                .catch(err => {
                    console.error('Cannot delete formatter', err);
                    toast.error(t(`sauron.formatter.toast.delete.error`));
                });
        },
        [organizationId, formatter, backLink]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[
                    <div key={0}>
                        <Button
                            primary
                            inverted={true}
                            label={t('common.cta.save')}
                            onClick={handleSave}
                            disabled={!areMandatoryFieldsSet || isSubmitClicked || !user.canManageFormatters(organizationId)}
                        />
                        {id && user.canManageFormatters(organizationId) && (
                            <ButtonDropdown
                                key="dropdown"
                                disabled={!formatter || loadingFormatter}
                                trigger={<Button key={1} inverted icon={<Icon name={'chevron-bottom'} />} label={t('common.actions')} />}
                                items={<FormatterActions formatter={formatter} handleAction={handleAction} actions={['delete']} />}
                            />
                        )}
                    </div>
                ]}
                title={t(`sauron.formatter.${id ? 'edit' : 'create'}.title`)}
                backLink={{
                    onClick: () => {
                        history.push(backLink);
                    }
                }}
            />
            <FrameCard title={t(`sauron.formatter.create.details.title`)} subtitle={t(`sauron.formatter.create.details.subtitle`)} separator>
                <LoadingCard loading={!organizationId && loadingOrganizations && !search}>
                    <div className={css['formatter-input']}>
                        <InfoTextTooltip
                            className={css['formatter-input-label']}
                            title={t('sauron.formatter.name')}
                            subtitle={t('sauron.formatter.name-subtitle')}
                            tooltip={false}
                        />
                        <Input
                            value={form?.name}
                            placeholder={t('sauron.formatter.name_placeholder')}
                            onChange={({ target: { value } }) => {
                                setForm('name', value);
                            }}
                            disabled={!user.canManageFormatters(organizationId)}
                        />
                    </div>

                    <div className={css['formatter-section-title']}>{t(`sauron.formatter.section_orga`)}</div>
                    <Section>
                        <SelectHierarchyLabel
                            options={options}
                            searchValue={search}
                            disabled={!id || !organizationId || !user.canManageFormatters(organizationId)}
                            value={form?.orgaId | Number(organizationId)}
                            placeholder={t('common.select')}
                            label={t('sauron.formatter.organizer')}
                            externalOptions={options}
                            onExternalSearch={value => setSearch(value)}
                            onClear={() => {
                                setForm('orgaId', null);
                            }}
                            onChange={value => {
                                setForm('orgaId', value.toString());
                            }}
                        />
                    </Section>
                    <div className={css['formatter-input']} datatype={!user.canManageFormatters(organizationId) && 'disabled-json-editor'}>
                        <InputLabel label={t('sauron.formatter.values')} />
                        <JsonEditorField
                            values={form?.values}
                            loading={loadingFormatter}
                            onChange={values => {
                                setForm('values', values);
                            }}
                            setIsValid={setIsValidJson}
                        />
                    </div>
                    <div className={css['formatter-input']}>
                        <InputLabel label={t('sauron.formatter.filters')} />
                        <Input
                            value={form?.filter}
                            placeholder={t('sauron.formatter.filters_placeholder')}
                            onChange={({ target: { value } }) => {
                                setForm('filter', value);
                            }}
                            disabled={!user.canManageFormatters(organizationId)}
                        />
                    </div>
                    <div className={css['formatter-input']}>
                        <InputLabel label={t('sauron.formatter.template')} />
                        <Input
                            value={form?.template}
                            placeholder={t('sauron.formatter.template_placeholder')}
                            onChange={({ target: { value } }) => {
                                setForm('template', value);
                            }}
                            disabled={isOriginTemplate || !user.canManageFormatters(organizationId)}
                        />
                    </div>
                    <div>
                        <CheckboxLabel
                            label={t('sauron.formatter.default_template')}
                            checked={isOriginTemplate}
                            onChange={({ target: { checked } }) => {
                                setForm('template', checked ? '__origin__' : '');
                            }}
                            disabled={!user.canManageFormatters(organizationId)}
                        />
                    </div>
                </LoadingCard>
            </FrameCard>
        </>
    );
};
