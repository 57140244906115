import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import css from './loadingCard.module.css';

export default function LoadingCard({ loading = false, label = null, children = null, className = '', height = '30vh' }) {
    const { t } = useTranslation();

    if (loading) {
        return (
            <div className={className} style={{ display: 'flex', position: 'relative', alignItems: 'center', height: height }}>
                <Loader style={{ zIndex: '3' }} active>
                    {label || t('common.loading')}
                </Loader>
            </div>
        );
    }

    return children;
}

LoadingCard.propTypes = {
    loading: PropTypes.bool
};

const SelectLoadingCard = () => {
    return (
        <div className={css['select-loader']}>
            <LoadingCard loading />
        </div>
    );
};

const SwitchLoadingCard = () => {
    return (
        <div className={css['switch-loader']}>
            <LoadingCard loading />
        </div>
    );
};

export const LoadingOptions = [{ text: <SelectLoadingCard />, value: null }];
export const SwitchLoadingOptions = [<SwitchLoadingCard key="loading" />];
