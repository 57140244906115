import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Dropdown, Icon, Card, StopPropagation } from '@weezevent/nacre';
import { WeezTicketBanner, WeezAccessBanner, WeezPayBanner, WeezTargetBanner } from '../../images';

import css from './list.module.css';
import { usePermissions } from '../../models/user/permissions';

const ORIGINS = ['http://localhost', 'https://admin.uat'];

const OrganizationCard = React.memo(({ organization }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const user = usePermissions();
    const { name, id } = organization;

    const organizationName = React.useMemo(() => {
        if (!name) {
            return t('sauron.organizations.organization_without_name');
        }
        return name;
    }, [name]);

    return (
        <Card key={id} className={css['organization-card']} onClick={() => history.push(`organizations/O${id}/users`)}>
            <div className={css['organization-header']}>
                <div>
                    <div className={css['organization-id-container']}>
                        <Icon name="institution" />
                        <div className={css['organization-id']}>O {id}</div>
                    </div>
                    <div className={css['organization-name']}>{organizationName}</div>
                </div>
                <div className={css['organization-dropdown']}>
                    <StopPropagation>
                        <Dropdown
                            items={[
                                <Dropdown.Item key={0} item={t('sauron.organizations.see-users')} onClick={() => history.push(`organizations/O${id}/users`)} />,

                                user.canManageWebhooks() && (
                                    <Dropdown.Item key={1} item={t('sauron.organizations.see-webhooks')} onClick={() => history.push(`organizations/O${id}/tools/webhooks`)} />
                                )
                            ]}
                        />
                    </StopPropagation>
                </div>
            </div>
            <div className={css['organization-actions']}>
                <StopPropagation>
                    <div className={css['organization-bo-items']}>
                        <BoItem logo={WeezTicketBanner} orgaId={id} name="ticket" primaryColor={'#F5F7FF'} />
                        <BoItem logo={WeezAccessBanner} orgaId={id} name="access" primaryColor={'#F4FDFC'} />
                        <BoItem logo={WeezPayBanner} orgaId={id} name="pay" primaryColor={'#FFFEF5'} />
                        <BoItem logo={WeezTargetBanner} orgaId={id} name="target" primaryColor={'#FFF5F6'} />
                    </div>
                </StopPropagation>
            </div>
        </Card>
    );
});

export default OrganizationCard;

const BoItem = React.memo(({ logo, name, orgaId, primaryColor }) => {
    const { t } = useTranslation();

    const redirectUri = React.useCallback(() => {
        const url = window.location.origin;
        const path = name === 'pay' ? `${name}/#!/${orgaId}` : `${name}/O${orgaId}`;
        let redirectUri = `${url}/${path}`;

        if (ORIGINS.some(orig => url.startsWith(orig))) {
            // If we are on localhost or uat, it's unsure the product is hosted so let's redirect to staging.
            redirectUri = `https://admin.weezevent.be/${path}`;
        }
        window.location.replace(redirectUri);
    }, [name, orgaId]);

    return (
        <div onClick={redirectUri} className={css['bo-item']} style={{ backgroundColor: primaryColor }}>
            <img src={logo} className={css['bo-item-logo']} alt={'logo'} />
            <div className={css['bo-item-text']}>{t(`sauron.organizations.${name}-bo`)}</div>
        </div>
    );
});
