import { BaseAPIModel } from '@weezevent/weezjs-core';

export class User extends BaseAPIModel {
    fields() {
        return {
            username: { primaryKey: true, readOnly: true },
            name: {}
        };
    }

    getEmail() {
        return this.email || '';
    }

    get resolvedName() {
        const firstName = this.object.first_name;
        const lastName = this.object.last_name.toUpperCase();
        const app = this.object.application_name;
        return app ? app : (firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName) || '-';
    }

    get clientId() {
        const name = this.object.username;
        if (!name.includes('app_')) {
            return null;
        }
        return name.replace('app_', '');
    }

    static listUrl({ organizationId, pathSegment }) {
        if (!organizationId) {
            return `/ticket/${pathSegment}`;
        }
        return `/ticket/organizations/${organizationId}/${pathSegment}`;
    }
}
