import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@weezevent/weezjs-core';

import { Webhook } from '../../../models';
import { Button, Modal, ToastContext } from '@weezevent/nacre';

import { WebHookDetails, WebHookOptions } from './WebHookBlocks';
import { LoadingCard } from '../../../components';

export const WebHookModal = ({ webhook, loading, organizationId, onClose, sync }) => {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);

    const [form, setForm] = useForm({ ...webhook.original_object });

    const isSubmitDisabled = React.useMemo(() => {
        return !form.criteria.organization_id || !form.criteria.type || !form.target;
    }, [form]);

    const handleSave = React.useCallback(() => {
        let webhook = new Webhook({ ...form });

        webhook
            .save()
            .then(response => {
                if (response.status === 201 || response.status === 200) {
                    onClose();
                    sync();
                    toast.success(t(`sauron.webhook.toast.success`));
                }
            })
            .catch(() => {
                toast.error(t(`sauron.webhook.toast.error`));
            });
    }, [form, onClose, sync]);

    return (
        <Modal
            size="large"
            allowExternalClosing={false}
            onClose={onClose}
            title={t('sauron.webhook.edit.title')}
            cancelButton={<Button onClick={onClose} label={t('common.cta.cancel')} />}
            submitButton={<Button primary onClick={handleSave} disabled={isSubmitDisabled} label={t('common.cta.save')} />}
        >
            <LoadingCard loading={loading || !form.id}>
                <WebHookDetails id={form.id} form={form} setForm={setForm} organizationId={organizationId} />
                <WebHookOptions id={form.id} form={form} setForm={setForm} organizationId={organizationId} />
            </LoadingCard>
        </Modal>
    );
};
