import React from 'react';
import moment from 'moment/moment';
import { Date, TimeInput } from '@weezevent/nacre';
import css from './dateTimeInput.module.css';

export const DateTimeInput = ({ initialDate, onChange, locale }) => {
    const [date, setDate] = React.useState(moment(initialDate));

    const time = React.useMemo(() => (initialDate ? moment(initialDate).format('HH:mm') : null), []);

    const handleTime = React.useCallback(
        value => {
            const [hour, minute] = value?.split(':').map(Number);
            date?.hours(hour);
            date?.minutes(minute);
            setDate(date);
            onChange(date);
        },
        [date]
    );

    return (
        <div className={css['date-time-input']}>
            <div className={css['date-container']}>
                <Date.Input
                    value={initialDate || null}
                    locale={locale}
                    language={locale}
                    onChange={value => {
                        setDate(value);
                        onChange(value);
                    }}
                />
                <TimeInput
                    disabled={!initialDate}
                    value={initialDate ? time : null}
                    step={15}
                    onChange={value => {
                        handleTime(value);
                    }}
                />
            </div>
        </div>
    );
};

export default DateTimeInput;
