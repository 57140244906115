import React from 'react';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { fetcher } from '@weezevent/weezjs-core';
import { useOnTokenCallback } from '@weezevent/connect';

const PermissionsContext = React.createContext(null);
fetcher.initialize(window.weezConfig.api.apiUrl);

export const usePermissions = () => {
    return React.useContext(PermissionsContext);
};

export const PermissionsLayer = ({ children }) => {
    useOnTokenCallback(authorizationHeader => {
        if (!authorizationHeader) {
            return;
        }
        fetcher.setAuthorizationHeader(`${authorizationHeader.charAt(0).toUpperCase()}${authorizationHeader.slice(1)}`);
    });
    const [currentPermissions, setCurrentPermissions] = React.useState(null);

    const getPermissions = React.useCallback(() => {
        Permissions.load(fetcher, setCurrentPermissions).then(permissions => setCurrentPermissions(permissions));
    }, []);

    // Get permissions
    React.useEffect(() => {
        getPermissions();
    }, []);

    if (!currentPermissions) {
        return <Loading />;
    }

    return <PermissionsContext.Provider value={currentPermissions}>{children}</PermissionsContext.Provider>;
};

PermissionsLayer.propTypes = {
    children: PropTypes.node
};

export class Permissions {
    static async load(fetcher, setCurrentPermissions) {
        // Mithrandir links have trailing slash
        try {
            const { response } = await fetcher.get('/ticket/_self/', {
                allow_cache: false,
                cache: false
            });
            return new this(fetcher, setCurrentPermissions, response.roles);
        } catch {
            return new this(fetcher, setCurrentPermissions, []);
        }
    }

    constructor(fetcher, setCurrentPermissions, roles) {
        invariant(roles instanceof Array, `Bad roles ${roles}`);
        this._roles = roles;
        this._fetcher = fetcher;
        this._setCurrentPermissions = setCurrentPermissions;
    }

    reload() {
        fetcher
            .get('/ticket/_self/', {
                allow_cache: false,
                cache: false
            })
            .then(({ response }) => {
                this._roles = response.roles;
                this._setCurrentPermissions(this);
            });
    }

    get capacities() {
        return new Set(
            this._roles
                .map(role => role.role.capacities)
                .flat()
                .map(capacity => capacity.slug)
        );
    }

    get roles() {
        return this._roles.map(role => role.role.slug);
    }

    hasRole(role) {
        return this._roles.find(r => r.role.slug === role.toLowerCase());
    }

    hasRoles() {
        return this._roles?.length > 0;
    }

    isAdmin() {
        return this._roles.some(r => r.role.slug === 'admin' && r.context.level === 0);
    }

    isOrganizationOwner(organizationId) {
        return this._roles.some(r => r.role.context.value === String(organizationId));
    }

    canSeeFeatures(organizationId) {
        if (this.isAdmin()) {
            return true;
        }
        const allowedCapacities = ['RoleCapacity.ADMIN', 'RoleCapacity.OWNER'];

        return this._roles.some(r => (r.context.value === String(organizationId) || r.context.level === 0) && r.role.capacities.some(c => allowedCapacities.includes(c.slug)));
    }

    canManageWebhooks() {
        if (!this.isAdmin()) {
            return this._roles.some(r => r.role.capacities.find(c => c.slug === 'PookCapacity.WEBHOOKS'));
        }
        return true;
    }

    canEditWebhooks() {
        if (!this.isAdmin()) {
            return this._roles.some(r => r.role.capacities.find(c => c.slug === 'PookCapacity.WEBHOOKS_ADMIN'));
        }
        return true;
    }

    canManageFormatters(organizationId) {
        if (!this.isAdmin()) {
            return this._roles.some(
                r => (r.context.value === String(organizationId) || r.context.level === 0) && r.role.capacities.find(c => c.slug === 'PookCapacity.FORMATTERS_ADMIN')
            );
        }
        return true;
    }

    canManageRoles() {
        if (!this.isAdmin()) {
            return this._roles.some(r => r.role.capacities.find(c => c.slug === 'RoleCapacity.ADMIN'));
        }
        return true;
    }

    hasCapacity(capacity, organization_id) {
        return (
            this._roles
                // lookup match roles
                .filter(r => r.context.level === 0 || (organization_id && r.context.level === 1 && r.context.value === organization_id.toString()))
                // find at least one role with the capacity
                .some(r => r.role.capacities.find(c => c.slug === capacity))
        );
    }

    capacityOrga(organization_id) {
        return new Set(
            this._roles
                .filter(r => r.context.level === 0 || (r.context.level === 1 && r.context.value === organization_id.toString()))
                .map(role => role.role.capacities)
                .flat()
                .map(capacity => capacity.slug)
        );
    }

    hasRightsOnOrga(organization_id) {
        // To check if user has right on this orga
        // 1 - We check on roles that are not for minisite (all minisite are on the new bo)
        // 2 - Check only on role who have [TICKET] in, to exclude all role like [TARGET] who also have right on this orga but not for ticket
        // 3 - get the first context-level and if user have rights, context value should match the organization_id

        return this._roles.some(
            r => r.role.slug !== 'minisite' && r.role.name.toUpperCase().includes('[TICKET]') && r.context.level === 1 && r.context.value === organization_id.toString()
        );
    }

    hasPermissionsCrossOrga() {
        //TODO : Remove filter on slug when nBO is open
        return this._roles.some(r => r.context.level === 0 && r.role.slug !== 'minisite');
    }

    canAccessNBO(organization_id) {
        const isAdmin = this.isAdmin();
        const hasPermissionsCrossOrga = this.hasPermissionsCrossOrga();
        const hasRightsOnOrga = this.hasRightsOnOrga(organization_id);

        return isAdmin || hasPermissionsCrossOrga || hasRightsOnOrga;
    }

    hasCapacities(capacities, organizationId) {
        const userCapacities = this.capacityOrga(organizationId);
        return capacities.find(capacity => userCapacities.has(capacity));
    }
}
