import { BaseAPIModel, fetcher } from '@weezevent/weezjs-core';

export class ActionModel extends BaseAPIModel {
    static buildPayload(params, action) {
        return {
            type: action.action,
            resource_query_filter: this.parseFilters(params.filters, action),
            config: {}
        };
    }

    static getFilters(filters, action) {
        if (!action.allow_filters) {
            return action.default_filters;
        }
        return { ...action.default_filters, ...filters };
    }

    static parseFilters(filters, action) {
        const _filters = this.getFilters(filters, action);
        return Object.entries(_filters).reduce((acc, [key, value]) => {
            acc[key] = Array.isArray(value) ? value.join(',') : value;
            return acc;
        }, {});
    }

    static getUrl(params) {
        if (!params.id) {
            return `${this.listUrl(params)}`;
        }
        return `${this.listUrl(params)}/${params.id}`;
    }

    static getModelActions() {
        throw new Error(`[${this.name}] must implement it's own getModelActions method`);
    }

    static getAction(params) {
        const action = this.getModelActions().find(it => it.action === params.type || it.label === params.type);
        if (!params.type || !action) {
            throw new Error(`[${this.constructor.name}BulkAction] type is missing or does not match any registered action`);
        }
        return this.validate(params, action);
    }

    static getRegisteredActions(actions = []) {
        if (!actions?.length) {
            return this.getModelActions().filter(it => it.is_bulk_action);
        }
        return actions.map(act => this.getAction({ type: act }));
    }

    static validate(params, action) {
        if (!action.required_fields.every(it => Object.keys(params).includes(it))) {
            throw new Error(`[${params.type}] [${action.required_fields.join(', ')}] are mandatory`);
        }
        return action;
    }

    static executeBulkAction(params) {
        const action = this.getAction(params);
        const url = this.getUrl(params);
        const data = this.buildPayload(params, action);
        return fetcher
            .post(`${url}/${action.endpoint}`, { data })
            .then(({ response }) => response)
            .catch(error => error)
            .finally(response => response);
    }

    static pollBulkAction(response) {
        const result = new ActionResponse(response);
        return result.poll().then(rsp => {
            return rsp;
        });
    }
}

class ActionResponse {
    constructor(args = {}) {
        this.config = args.config;
        this.is_asynced = args.is_asynced;
        this.resource_query_filter = args.resource_query_filter;
        this.sync_report = args.sync_report;
        this.task_id = args.task_id;
        this.type = args.type;
        this.status = 'pending';
        this.response = {};
        this.summary = {};
    }

    buildSummary() {
        return this.response.results.reduce(
            (acc, res) => {
                acc['total'] += res.report.count_items_total;
                acc['success'] += res.report.count_items_success;
                acc['error'] += res.report.count_items_error;
                return acc;
            },
            { total: 0, success: 0, error: 0 }
        );
    }

    updateOutput(response) {
        this.response = response;
        this.summary = this.buildSummary(response);
    }

    isComplete() {
        return this.response.results?.every(rs => rs.state === 'SUCCESS');
    }

    hasErrors() {
        return this.response.results?.some(rs => Boolean(rs.error));
    }

    poll(intervalTime = 5000) {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                fetcher
                    .get(`/webhook/async-tasks/actions?task_id=${this.task_id}`, {
                        allow_cache: false,
                        cache: false
                    })
                    .then(({ response }) => {
                        this.updateOutput(response);

                        if (this.isComplete()) {
                            clearInterval(interval);
                            this.status = 'success';
                            resolve(this);
                        } else if (this.hasErrors()) {
                            clearInterval(interval);
                            this.status = 'error';
                            reject(new Error(this));
                        }
                    })
                    .catch(error => {
                        clearInterval(interval);
                        reject(error);
                    });
            }, intervalTime);
        });
    }
}
