import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Formatter } from '../../../models';

import { Dropdown } from '@weezevent/nacre';

export const FormattersListActions = React.memo(({ actions = [], handleAction, selectedItems }) => {
    const { t } = useTranslation();
    const _actions = Formatter.getRegisteredActions(actions);

    return _actions.map(({ action, label, delete_item, disabled: { condition, key } }) => {
        const disabled = React.useMemo(() => {
            if (!condition) {
                return false;
            }
            return selectedItems.some(it => {
                return it[key];
            });
        }, [condition, key, selectedItems]);

        return (
            <Dropdown.Item key={label} item={t(`sauron.formatter.bulk_actions.${label}`)} onClick={() => handleAction({ action })} disabled={disabled} deleteItem={delete_item} />
        );
    });
});

FormattersListActions.propTypes = {
    handleAction: PropTypes.func.isRequired,
    selectedItems: PropTypes.array
};

FormattersListActions.defaultProps = {
    handleAction: () => {},
    selectedItems: []
};

export const FormatterActions = React.memo(({ formatter, actions = [], handleAction }) => {
    const { t } = useTranslation();
    const _actions = Formatter.getRegisteredActions(actions);

    return _actions.map(({ action, method, label, delete_item, disabled: { condition, key, bypass_item } }) => {
        const disabled = React.useMemo(() => {
            if (!condition || bypass_item) {
                return false;
            }
            return Boolean(formatter[key]);
        }, [condition, formatter, bypass_item, key]);

        return <Dropdown.Item key={action} item={t(`sauron.formatter.${label}`)} onClick={() => handleAction(formatter, method)} deleteItem={delete_item} disabled={disabled} />;
    });
});

FormatterActions.propTypes = {
    formatter: PropTypes.object,
    handleAction: PropTypes.func.isRequired,
    actions: PropTypes.array
};

FormatterActions.defaultProps = {
    handleAction: () => {},
    actions: []
};
