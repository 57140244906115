import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetcher } from '@weezevent/weezjs-core';
import { User, Context } from '../../../models';
import { WeezHeader, Button, Icon, AnchorNav, SplitContainer, ToastContext } from '@weezevent/nacre';

import { UserSideBlock, UserInformations, UserRoles, UserHistory } from './UserBlocks';
import RoleCreationModal from './roles/RoleCreationModal';
import { useLocationSegment } from '../../../hooks';
import ModalSecret from './ModalSecret';

export const UserProfile = ({ organizationId, userId }) => {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);
    const history = useHistory();
    const [pathSegment, pathSegment_singular, location] = useLocationSegment(organizationId);
    const [addRoleModal, setAddRoleModal] = React.useState(false);
    const { openModalSecret, client_id, client_secret } = location.state || {};
    const [openSecret, setOpenSecret] = React.useState(() => openModalSecret);

    const [user, loadingUser, syncUser] = User.useApiModel(
        {
            id: userId,
            organizationId,
            pathSegment,
            launch: userId,
            cache: false,
            allow_cache: false
        },
        [userId, organizationId, pathSegment]
    );

    const userName = React.useMemo(() => {
        if (loadingUser || !user) {
            return ' ';
        }

        if (!user.first_name && !user.last_name && !user.application_name) {
            return t(`sauron.${pathSegment_singular}.anonymous`);
        }
        return `${user.resolvedName}`;
    }, [user, loadingUser]);

    const handleDelete = React.useCallback(
        _role => {
            const { context, role } = _role;
            const url = `/ticket/roles/${role.slug}/users/`;
            fetcher
                .delete(url, {
                    data: {
                        context: new Context(context).values,
                        user: {
                            username: userId
                        }
                    }
                })
                .then(response => {
                    if (response.status === 204) {
                        toast.success(t('sauron.toasts.roles.delete-role-success'));
                    }
                })
                .catch(() => {
                    toast.error(t('sauron.toasts.error'));
                })
                .finally(() => {
                    syncUser();
                });
        },
        [userId, syncUser]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[<Button key={0} primary inverted disabled icon={<Icon name={'chevron-bottom'} />} label={t('common.actions')} onClick={() => {}} />]}
                title={userName}
                backLink={{
                    onClick: () => {
                        history.push(organizationId ? `/organizations/O${organizationId}/${pathSegment}` : `/${pathSegment}`);
                    }
                }}
            />
            <AnchorNav
                rootId="main"
                items={[
                    {
                        id: 'information',
                        title: t(`sauron.${pathSegment_singular}.information`)
                    },
                    {
                        id: 'roles',
                        title: t('common.roles')
                    },
                    {
                        id: 'history',
                        title: t('common.history')
                    }
                ]}
            >
                {openSecret && <ModalSecret open={openModalSecret} onClose={() => setOpenSecret(false)} clientId={client_id} clientSecret={client_secret}></ModalSecret>}
                {addRoleModal && <RoleCreationModal open={addRoleModal} organizationId={organizationId} user={user} onClose={() => setAddRoleModal(false)} sync={syncUser} />}
                <SplitContainer displayAdditional={false} additional={<UserSideBlock loading={loadingUser} user={user} />}>
                    <UserInformations loading={loadingUser} user={user} name={userName} />
                    <UserRoles loading={loadingUser} user={user} onToggle={() => setAddRoleModal(true)} handleDelete={handleDelete} />
                    <UserHistory loading={loadingUser} user={user} />
                </SplitContainer>
            </AnchorNav>
        </>
    );
};
