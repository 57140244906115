import i18n from '../i18n/i18n';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const useDateFormat = ({ date, event, tz, dateStyle, timeStyle }) => {
    const locale = i18n.language;
    const timeZone = event ? event?.timezone : tz;

    if (!date) {
        date = moment();
    }

    const dateWithDay = new Date(date).toLocaleDateString(locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone
    });

    const properDate = new Date(date).toLocaleDateString(locale, {
        dateStyle,
        timeZone
    });

    const properTime = new Date(date).toLocaleTimeString(locale, {
        timeStyle,
        timeZone
    });

    return { dateWithDay, properTime, properDate };
};

export default useDateFormat;

useDateFormat.propTypes = {
    date: PropTypes.string.isRequired,
    event: PropTypes.object,
    tz: PropTypes.string,
    dateStyle: PropTypes.string.isRequired,
    timeStyle: PropTypes.string.isRequired
};
useDateFormat.defaultProps = {
    event: null,
    tz: moment.tz.guess(),
    dateStyle: 'short',
    timeStyle: 'short'
};
