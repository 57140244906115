import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SelectButton, DropdownLight } from '@weezevent/nacre';

import css from './pagination-dropdown.module.css';

const PaginationDropDown = ({ title, tooltip, label, count, selected, disabled, loading, limit, onChange, pageSizes, displaySelect, displayDropdown, items }) => {
    const { t } = useTranslation();

    const resolvedTitle = React.useMemo(() => {
        if (!selected.length > 0) {
            return t('common.action');
        }
        return title;
    }, [selected, title]);

    const pageSizesOptions = React.useMemo(() => {
        return pageSizes.map((value, key) => ({ key, label: t(label, { count: value }), value }));
    }, [pageSizes, label]);

    const display = React.useMemo(() => {
        return displayDropdown && !loading && count >= Math.min.apply(Math, pageSizes);
    }, [loading, pageSizes, count]);

    if (!displayDropdown && !displaySelect) {
        return null;
    }

    return (
        <div className={css['pagination-dropdown']}>
            <div className={css['pagination-dropdown-block']}>
                {displaySelect && (
                    <SelectButton tooltipTitle={tooltip} title={resolvedTitle} objects={selected} disabled={disabled}>
                        {items}
                    </SelectButton>
                )}
            </div>
            <div className={css['pagination-dropdown-block']}>
                {display && <DropdownLight position={'bottom-end'} className={css['dropdown-pagesize']} value={limit} onChange={onChange} items={pageSizesOptions} />}
            </div>
        </div>
    );
};

PaginationDropDown.propTypes = {
    title: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tooltip: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.array,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    limit: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    pageSizes: PropTypes.array,
    displaySelect: PropTypes.bool,
    displayDropdown: PropTypes.bool
};

PaginationDropDown.defaultProps = {
    selected: [],
    disabled: false,
    loading: false,
    pageSizes: [50, 100, 250, 500],
    displaySelect: true,
    displayDropdown: true
};

export default PaginationDropDown;
