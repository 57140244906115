import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@weezevent/nacre';

export default function WebhookTriggerModal({ open = false, onAccept, onClose }) {
    const { t } = useTranslation();

    if (!open) {
        return null;
    }

    return (
        <Modal
            title={t('sauron.webhook.trigger')}
            open={open}
            onClose={onClose}
            submitButton={
                <Button
                    primary
                    onClick={() => {
                        onAccept();
                        onClose();
                    }}
                >
                    {t('sauron.webhook.trigger_button')}
                </Button>
            }
            cancelButton={<Button onClick={onClose}>{t(`common.cta.cancel`)}</Button>}
        >
            {t('sauron.webhook.trigger_message')}
        </Modal>
    );
}
