import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from '../../utils/auth/authRoute';
import { UsersList } from '../../pages/users/list';
import { UserProfile } from '../../pages/users/user/User';

export const UsersRouter = ({ match, organizationId }) => {
    const { path } = match;

    return (
        <Switch>
            <AuthRoute allow={['RoleCapacity.ADMIN', 'RoleCapacity.OWNER']} exact path={path} render={() => <UsersList organizationId={organizationId} />} />
            <AuthRoute
                exact
                allow={['RoleCapacity.ADMIN', 'RoleCapacity.OWNER']}
                path={`${path}/:userId`}
                render={({
                    match: {
                        params: { userId }
                    }
                }) => <UserProfile organizationId={organizationId} userId={userId} />}
            />
        </Switch>
    );
};
