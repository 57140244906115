import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Webhook as WebhookModel } from '../../models';
import { AnchorNav, Button, ButtonDropdown, Icon, WeezHeader } from '@weezevent/nacre';

import { WebHookActions, WebHookCalls, WebhookHeader, WebHookModal, WebHookPayloadModal } from './components';
import { usePermissions } from '../../models/user/permissions';

export const Webhook = ({ id, organizationId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const user = usePermissions();
    const [openModal, setOpenModal] = React.useState(false);
    const [payloadModal, setPayloadModal] = React.useState({ open: false, call: null });

    const [webhook, loadingWebhook, syncWebhook] = WebhookModel.useApiModel(
        {
            id,
            organizationId,
            cache: false,
            allow_cache: false
        },
        [id, organizationId]
    );

    const backLink = React.useMemo(() => {
        if (!organizationId) {
            return '/tools/webhooks';
        }
        return `/organizations/O${organizationId}/tools/webhooks`;
    }, [organizationId]);

    const handleAction = React.useCallback(
        (_, action) => {
            switch (action) {
                case 'toggle':
                    webhook.toggle({ organizationId }).then(() => syncWebhook());
                    break;
                case 'archive':
                    webhook.delete({ organizationId }).then(() => history.push(backLink));
                    break;
                default:
                    break;
            }
        },
        [organizationId, syncWebhook, backLink]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[
                    user.canEditWebhooks() && (
                        <ButtonDropdown
                            key="dropdown"
                            disabled={!webhook || loadingWebhook}
                            trigger={<Button key={1} inverted icon={<Icon name={'chevron-bottom'} />} label={t('common.actions')} />}
                            items={<WebHookActions webhook={webhook} handleAction={handleAction} actions={[webhook?.active ? 'disable' : 'enable', 'archive']} />}
                        />
                    )
                ]}
                title={webhook?.target || ' '}
                backLink={{
                    onClick: () => {
                        history.push(backLink);
                    }
                }}
            />
            <AnchorNav
                items={[
                    {
                        id: 'webhook_profile',
                        title: t('common.profile')
                    },
                    {
                        id: 'webhook_logs',
                        title: t('common.logs')
                    }
                ]}
                rootId="root"
            >
                {openModal && <WebHookModal webhook={webhook} organizationId={organizationId} onClose={() => setOpenModal(false)} sync={syncWebhook} />}
                {payloadModal.open && <WebHookPayloadModal call={payloadModal.call} onClose={() => setPayloadModal({ open: false, call: null })} />}

                <WebhookHeader webhook={webhook} organizationId={organizationId} setOpenModal={setOpenModal} loading={loadingWebhook || !webhook} />
                <WebHookCalls webhook={webhook} organizationId={organizationId} setOpenModal={setPayloadModal} syncWebhook={syncWebhook} />
            </AnchorNav>
        </>
    );
};
