import React from 'react';
import PropTypes from 'prop-types';

import css from './label-details.module.css';

const LabelDetails = ({ label, children }) => {
    return (
        <span className={css['label-detail']}>
            <span className={css['label-detail-label']}>{label}</span>
            <span className={css['label-detail-content']}>{children}</span>
        </span>
    );
};

LabelDetails.propTypes = {
    label: PropTypes.string
};

LabelDetails.defaultProps = {
    label: ''
};

export default LabelDetails;
