import React from 'react';
import { ViewPort, MenuContainer } from '@weezevent/nacre';
import { useHistory, useLocation } from 'react-router-dom';
import { WeezLogo } from '../../images';
import SideBarMenu, { RootMenu } from './sidebar/SideBarMenu';
import TopBarModules from '../topBar/topBarModules';
import { usePermissions } from '../../models/user/permissions';

export const Template = ({ children }) => {
    const { capacities, _roles } = usePermissions();
    const history = useHistory();
    const { pathname } = useLocation();

    const organizationId = React.useMemo(() => {
        const rule = new RegExp(/organizations\/O(\d+)(?=\/|$)/);
        return pathname.match(rule)?.[1] || null;
    }, [pathname]);

    return (
        <ViewPort logo={WeezLogo} topBarModules={<TopBarModules />} displayMenuButton onClickLogo={() => history.push('/organizations')}>
            <MenuContainer
                menu={<SideBarMenu organizationId={organizationId} capacities={capacities} roles={_roles} />}
                optionalMenu={organizationId && <RootMenu capacities={capacities} />}
            >
                {children}
            </MenuContainer>
        </ViewPort>
    );
};
