import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@weezevent/nacre';

import css from './webhook.module.css';

export const WebHookPayloadModal = ({ call, onClose }) => {
    const { t } = useTranslation();

    const jsonPayload = React.useMemo(() => JSON.stringify(call.payload, null, 4), [call]);

    const handleCopy = React.useCallback(() => {
        navigator.clipboard.writeText(jsonPayload);
    }, [jsonPayload]);

    return (
        <Modal
            size="large"
            allowExternalClosing={false}
            onClose={onClose}
            title={`${t('common.notification')} ${call.pk}`}
            submitButton={<Button primary onClick={handleCopy} icon={<Icon name="copy" />} label={t('common.cta.copy')} />}
        >
            <pre className={css['webhook-call-payload']}>{jsonPayload}</pre>
        </Modal>
    );
};
