import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDateFormat } from '../../../hooks';
import { Icon, Status } from '@weezevent/nacre';

import { TicketCard, LabelDetails, LoadingCard } from '../../../components';
import { HeaderLink } from '../list';

import css from './webhook.module.css';
import { usePermissions } from '../../../models/user/permissions';

export const WebhookHeader = ({ webhook, organizationId, loading, setOpenModal }) => {
    return (
        <TicketCard id="webhook_profile">
            <LoadingCard loading={loading}>
                <TicketCardContent webhook={webhook} organizationId={organizationId} setOpenModal={setOpenModal} />
            </LoadingCard>
        </TicketCard>
    );
};

const TicketCardContent = ({ webhook, organizationId, setOpenModal }) => {
    const { t } = useTranslation();
    const user = usePermissions();

    const expirationDate = React.useMemo(() => {
        if (!webhook?.expire_at) {
            return '-';
        }
        const { properDate, properTime } = useDateFormat({ date: webhook?.expire_at, timeStyle: 'short' });
        return `${properDate} ${properTime}`;
    }, [webhook]);

    return (
        <>
            <TicketCard.Header>
                <div className={css['webhook-informations']}>
                    <div className={css['webhook-informations-content']}>
                        <LabelDetails label={t('common.creator')}>{webhook.creator}</LabelDetails>
                        <LabelDetails label={t('common.target')}>{webhook.target}</LabelDetails>
                    </div>
                    {user.canEditWebhooks() && (
                        <div className={css['webhook-edit']} onClick={() => setOpenModal(true)}>
                            <Icon name="edit" />
                            {t('common.cta.edit')}
                        </div>
                    )}
                </div>
                <div className={css['webhook-details']}>
                    <LabelDetails label={t('common.organization')}>
                        <HeaderLink item={webhook.organization_id} organizationId={organizationId} />
                    </LabelDetails>
                    <LabelDetails label={t('common.formatter')}>
                        <HeaderLink item={webhook.formatter} organizationId={organizationId} type="formatter" />
                    </LabelDetails>
                    <LabelDetails label={t('common.method')}>{webhook.criteria.method || 'both'}</LabelDetails>
                    <LabelDetails label={t('common.secret')}>{webhook.secret_is_defined ? 'set' : 'not set'}</LabelDetails>
                    <LabelDetails label={t('common.type')}>{webhook.criteria.type}</LabelDetails>
                    <LabelDetails label={t('common.expiration_date')}>{expirationDate}</LabelDetails>
                </div>
            </TicketCard.Header>

            <TicketCard.Footer>
                <div className={css['webhook-subdetails']}>
                    <LabelDetails label={t('common.status')}>
                        <Status color={webhook.active ? 'green' : null} responsive text={t(`common.${webhook.active ? 'active' : 'inactive'}`)} />
                    </LabelDetails>
                    <LabelDetails label={t('sauron.webhook.notifications.delivered')}>
                        <span className={css['webhook-calls-success']}>{webhook.success_call_count}</span>
                    </LabelDetails>
                    <LabelDetails label={t('sauron.webhook.notifications.failed')}>{<span className={css['webhook-calls-fail']}>{webhook.fail_call_count}</span>}</LabelDetails>
                </div>
            </TicketCard.Footer>
        </>
    );
};
