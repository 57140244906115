import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Input, Form, Accordion, Section, Icon, SelectHierarchyLabel, ToastContext } from '@weezevent/nacre';
import { useForm } from '@weezevent/weezjs-core';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../models/user/permissions';
import useFirstRender from '../../../utils/helpers/useFirstRender';
import { pushOrSplice, matchSearch, isEmail } from '../../../utils/utils';

import { Role, User, Organization } from '../../../models';

import { LoadingCard, LoadingOptions } from '../../../components';

import css from '../list.module.css';

const DEFAULT_PARAMS = { search: '', limit: 10, offset: 0 };

const NewUser = ({ organizationId, pathSegmentItems, open, onClose }) => {
    const { t } = useTranslation();
    const user = usePermissions();
    const history = useHistory();
    const toast = React.useContext(ToastContext);
    const isFirstRender = useFirstRender();
    const [loading, setLoading] = React.useState(false);
    const [searchRole, setSearchRole] = React.useState('');
    const [organizationsParams, setOrganizationsParams] = React.useState({ ...DEFAULT_PARAMS });
    const [currentSegment, currentSegment_singular] = pathSegmentItems;

    const isApplication = React.useMemo(() => {
        return currentSegment === 'applications';
    }, [currentSegment]);

    const default_form = React.useMemo(() => {
        let _form = {
            first_name: '',
            last_name: '',
            email: '',
            roles: [
                {
                    role: null
                }
            ]
        };
        if (organizationId) {
            _form.roles[0]['context'] = ['*', '*', organizationId];
        }
        return isApplication ? { application_name: '', ..._form } : _form;
    }, [organizationId, currentSegment]);

    const [form, setForm, setFormObject] = useForm({ ...default_form });

    const [roles, loadingRoles] = Role.useApiModel(
        {
            organizationId,
            cache: false,
            allow_cache: false,
            launch: open
        },
        [open, organizationId]
    );

    const [organizations, loadingOrganizations] = Organization.useApiModel(
        {
            cache: false,
            allow_cache: false,
            query: organizationsParams,
            launch: open && !organizationId
        },
        [organizationsParams, open, organizationId]
    );

    const [organization, loadingOrganization] = Organization.useApiModel(
        {
            id: organizationId,
            launch: open && Boolean(organizationId)
        },
        [open, organizationId]
    );

    const disabled = React.useMemo(() => {
        return (isApplication && !form.application_name) || !form.email || form.roles.some(role => !role.role) || loading || !isEmail(form.email);
    }, [form, form.roles, loading]);

    const rolesOptions = React.useMemo(() => {
        if (!roles || loadingRoles) {
            return LoadingOptions;
        }

        return roles.filter(role => matchSearch([role.name, role.slug], searchRole)).map(role => role.toOption());
    }, [roles, loadingRoles, searchRole, LoadingOptions]);

    const organizationsOptions = React.useMemo(() => {
        switch (true) {
            case !organizationId && loadingOrganizations:
            case Boolean(organizationId) && loadingOrganization:
                return LoadingOptions;
            case Boolean(organizationId) && !loadingOrganization && !organization:
            case !organizationId && !loadingOrganizations && !organizations:
                return [];
            case Boolean(organizationId):
                return [organization.toOption()];
            case organizationsParams.search:
                return organizations.map(org => org.toOption());
            default:
                return [{ key: 0, value: 'default', text: t('common.all') }, ...organizations?.map(org => org.toOption())];
        }
    }, [organization, organizations, loadingOrganization, loadingOrganizations, LoadingOptions, organizationId]);

    const handleRolesList = React.useCallback(
        value => {
            const roles = [...form.roles];
            let addition = {
                role: null
            };
            if (organizationId) {
                addition['context'] = ['*', '*', organizationId];
            }
            const new_roles = pushOrSplice(roles, addition, value);
            return setForm('roles', new_roles);
        },
        [form, organizationId]
    );

    const handleRole = React.useCallback(
        (name, value, index) => {
            let roles = [...form.roles];

            if (organizationId) {
                roles[index][name] = ['*', '*', organizationId];
            }

            if (name === 'context' && user.isAdmin()) {
                roles[index][name] = value !== 'default' ? ['*', '*', value] : [];
            } else {
                roles[index][name] = value;
            }

            setForm('roles', roles);
        },
        [form, user, organizationId]
    );

    const handleSubmit = React.useCallback(
        (next = false) => {
            setLoading(true);
            let user = new User({ ...form });
            user.roles = form.roles
                .filter(r => r)
                .map(role => {
                    if (!role.context) {
                        role.context = organizationId ? ['*', '*', organizationId] : [];
                    }
                    return role;
                });

            if (!isApplication) {
                user.client_id = window.weezConfig.connect.clientId;
            }

            user.save({ organizationId, pathSegment: currentSegment })
                .then(({ response: { client_id, client_secret, username }, status }) => {
                    if (status === 201) {
                        user.username = username;
                        let state = {};
                        if (isApplication) {
                            state = { openModalSecret: isApplication, client_id, client_secret };
                        }
                        toast.success(t(`sauron.${currentSegment}.toast-success`));

                        if (!next) {
                            onClose();
                            console.log(`/${currentSegment}/${user.username} ${state}`);
                            history.push(`/${currentSegment}/${user.username}`, state);
                        }
                    }
                })
                .catch(() => {
                    toast.error(t('sauron.toasts.error'));
                })
                .finally(() => {
                    if (next) {
                        setFormObject({ ...default_form }, true);
                    }
                    setLoading(false);
                });
        },
        [form, onClose, organizationId, currentSegment, default_form]
    );

    const handleSearch = value => {
        if (!value) {
            setOrganizationsParams(DEFAULT_PARAMS);
        }
        setOrganizationsParams({ ...DEFAULT_PARAMS, search: value });
    };

    if (!open) {
        return null;
    }

    return (
        <Modal
            size={'large'}
            open={open}
            allowExternalClosing={false}
            onClose={onClose}
            title={t(`sauron.${currentSegment}.add-${currentSegment_singular}-title`)}
            subtitle={t(`sauron.${currentSegment}.add-${currentSegment_singular}-subtitle`)}
            customFooter={
                <Button.Group>
                    <Button onClick={onClose} label={t('common.cta.cancel')} />
                    {!isApplication && <Button primary inverted onClick={() => handleSubmit(true)} disabled={disabled} label={t(`sauron.users.add-user-action`)} />}
                    <Button primary onClick={() => handleSubmit()} disabled={disabled} label={t('common.cta.save')} />
                </Button.Group>
            }
        >
            <LoadingCard loading={loadingRoles && isFirstRender}>
                {isApplication && (
                    <div className={css['user-input']}>
                        <Form.Label label={t('common.application')} />
                        <Input
                            errors={form.application_name}
                            value={form.application_name}
                            placeholder={t('common.application')}
                            onChange={({ target: { value } }) => {
                                setForm('application_name', value);
                            }}
                        />
                    </div>
                )}
                <div className={css['user-input']}>
                    <Form.Label label={t('common.first-name')} optionalText={t('common.optional')} />
                    <Input
                        value={form.first_name}
                        placeholder={t('common.first-name')}
                        onChange={({ target: { value } }) => {
                            setForm('first_name', value);
                        }}
                    />
                </div>
                <div className={css['user-input']}>
                    <Form.Label label={t('common.last-name')} optionalText={t('common.optional')} />
                    <Input
                        value={form.last_name}
                        placeholder={t('common.last-name')}
                        onChange={({ target: { value } }) => {
                            setForm('last_name', value);
                        }}
                    />
                </div>
                <div className={css['user-input']}>
                    <Form.Label label={t('common.email')} />
                    <Input
                        errors={!isEmail(form.email)}
                        value={form.email}
                        placeholder={t('common.email')}
                        onChange={({ target: { value } }) => {
                            setForm('email', value);
                        }}
                    />
                </div>
                {form.roles.map((role, index) => (
                    <div key={index} className={css['user-role-accordion']}>
                        <RoleItem
                            index={index}
                            role={role}
                            user={user}
                            searchRole={searchRole}
                            handleRole={handleRole}
                            handleSearch={handleSearch}
                            rolesOptions={rolesOptions}
                            setSearchRole={setSearchRole}
                            organizationId={organizationId}
                            handleRolesList={handleRolesList}
                            organizationsParams={organizationsParams}
                            organizationsOptions={organizationsOptions}
                        />
                    </div>
                ))}

                <div className={css['user-role-toggle']} onClick={() => handleRolesList()}>
                    <Icon name="plus" size="mini" />
                    <b>{t(`sauron.${currentSegment_singular}.add-role`)}</b>
                </div>
            </LoadingCard>
        </Modal>
    );
};

export default NewUser;

const RoleItem = ({
    index,
    role,
    rolesOptions,
    searchRole,
    setSearchRole,
    handleRolesList,
    handleRole,
    handleSearch,
    organizationId,
    organizationsParams,
    organizationsOptions
}) => {
    const { t } = useTranslation();

    const roleOrganization = React.useMemo(() => {
        if (!role.context || !role.context.length) {
            return null;
        }
        return Number(role.context[2]);
    }, [role]);

    return (
        <Accordion
            openByDefault
            title={
                <div className={css['user-role-title']}>
                    <div>{`${t('common.role')} ${index + 1}`}</div>
                    <Icon name="trash" onClick={() => handleRolesList(index)} />
                </div>
            }
        >
            <Section>
                <SelectHierarchyLabel
                    searchable
                    value={role.role}
                    options={rolesOptions}
                    externalOptions={rolesOptions}
                    onChange={value => handleRole('role', value, index)}
                    label={t('common.role')}
                    placeholder={t('common.select')}
                    placeholderSearch={t('common.search')}
                    searchValue={searchRole}
                    onExternalSearch={value => setSearchRole(value)}
                />

                <SelectHierarchyLabel
                    searchable
                    disabled={organizationId}
                    value={roleOrganization}
                    options={organizationsOptions}
                    externalOptions={organizationsOptions}
                    onChange={value => handleRole('context', value, index)}
                    label={t('common.context')}
                    placeholder={t('common.select')}
                    placeholderSearch={t('common.search')}
                    searchValue={organizationsParams.search}
                    onExternalSearch={value => handleSearch(value)}
                />
            </Section>
        </Accordion>
    );
};
