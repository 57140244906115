import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Webhook } from '../../../models';

import { Dropdown } from '@weezevent/nacre';

export const WebHooksListActions = React.memo(({ actions = [], handleAction, selectedItems }) => {
    const { t } = useTranslation();
    const _actions = Webhook.getRegisteredActions(actions);

    return _actions.map(({ action, label, delete_item, disabled: { condition, negate, key } }) => {
        const disabled = React.useMemo(() => {
            if (!condition) {
                return false;
            }
            return selectedItems.some(it => (negate ? !it[key] : it[key]));
        }, [condition, negate, key, selectedItems]);

        return <Dropdown.Item key={label} item={t(`sauron.webhook.bulk_actions.${label}`)} onClick={() => handleAction({ action })} disabled={disabled} deleteItem={delete_item} />;
    });
});

WebHooksListActions.propTypes = {
    handleAction: PropTypes.func.isRequired,
    selectedItems: PropTypes.array
};

WebHooksListActions.defaultProps = {
    handleAction: () => {},
    selectedItems: []
};

export const WebHookActions = React.memo(({ webhook, actions = [], handleAction }) => {
    const { t } = useTranslation();
    const _actions = Webhook.getRegisteredActions(actions);

    return _actions.map(({ action, method, label, delete_item, disabled: { condition, negate, key, bypass_item } }) => {
        const disabled = React.useMemo(() => {
            if (!condition || bypass_item) {
                return false;
            }
            return Boolean(negate ? !webhook[key] : webhook[key]);
        }, [condition, negate, webhook, bypass_item, key]);

        return <Dropdown.Item key={action} item={t(`sauron.webhook.${label}`)} onClick={() => handleAction(webhook, method)} deleteItem={delete_item} disabled={disabled} />;
    });
});

WebHookActions.propTypes = {
    webhook: PropTypes.object,
    handleAction: PropTypes.func.isRequired,
    actions: PropTypes.array
};

WebHookActions.defaultProps = {
    handleAction: () => {},
    actions: []
};
