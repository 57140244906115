import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from '../../utils/auth/authRoute';
import { WebhooksList } from '../../pages/webhooks/list';
import { FormattersList } from '../../pages/formatters/list';
import { Formatter } from '../../pages/formatters/Formatter';
import { Webhook } from '../../pages/webhooks/Webhook';
import { WebhookNew } from '../../pages/webhooks/WebhookNew';

export const WebhooksRouter = ({ match, organizationId }) => {
    const { path } = match;

    return (
        <Switch>
            {/* Webhooks routes */}
            <AuthRoute
                exact
                allow={['RoleCapacity.ADMIN', 'PookCapacity.WEBHOOKS', 'PookCapacity.WEBHOOKS_ADMIN']}
                path={`${path}/webhooks`}
                render={() => <WebhooksList organizationId={organizationId} />}
            />
            <AuthRoute
                exact
                allow={['RoleCapacity.ADMIN', 'PookCapacity.WEBHOOKS_ADMIN']}
                path={`${path}/webhooks/new`}
                render={() => <WebhookNew organizationId={organizationId} />}
            />
            <AuthRoute
                exact
                allow={['RoleCapacity.ADMIN', 'PookCapacity.WEBHOOKS', 'PookCapacity.WEBHOOKS_ADMIN']}
                path={`${path}/webhooks/:id`}
                render={basenameProps => {
                    const { id } = basenameProps.match.params;
                    return <Webhook organizationId={organizationId} id={id} />;
                }}
            />

            {/* Formatters routes */}
            <AuthRoute
                exact
                allow={['RoleCapacity.ADMIN', 'PookCapacity.FORMATTERS', 'PookCapacity.FORMATTERS_ADMIN']}
                path={`${path}/formatters`}
                render={() => <FormattersList organizationId={organizationId} />}
            />

            <AuthRoute
                exact
                allow={['RoleCapacity.ADMIN', 'PookCapacity.FORMATTERS_ADMIN']}
                path={`${path}/formatters/new`}
                render={() => <Formatter organizationId={organizationId} />}
            />

            <AuthRoute
                exact
                allow={['RoleCapacity.ADMIN', 'PookCapacity.FORMATTERS', 'PookCapacity.FORMATTERS_ADMIN']}
                path={`${path}/formatters/:id`}
                render={basenameProps => {
                    const { id } = basenameProps.match.params;
                    return <Formatter id={id} organizationId={organizationId} />;
                }}
            />
        </Switch>
    );
};
