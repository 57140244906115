import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from '@weezevent/weezjs-core';

import { Webhook } from '../../models';
import { Button, FrameCard, ToastContext, WeezHeader } from '@weezevent/nacre';

import { WebHookDetails, WebHookOptions } from './components/WebHookBlocks';

export const WebhookNew = ({ organizationId }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);

    const [form, setForm] = useForm({
        id: null,
        active: true,
        creator: null,
        criteria: {
            type: null,
            organization_id: organizationId
        },
        formatter: null,
        expire_at: null,
        secret: null,
        target: ''
    });

    const backLink = React.useMemo(() => {
        if (!organizationId) {
            return '/tools/webhooks';
        }
        return `/organizations/O${organizationId}/tools/webhooks`;
    }, [organizationId]);

    const isSubmitDisabled = React.useMemo(() => {
        return !form.criteria.organization_id || !form.criteria.type || !form.target;
    }, [form]);

    const handleSave = React.useCallback(() => {
        let webhook = new Webhook({ ...form });

        webhook
            .save()
            .then(response => {
                if (response.status === 201 || response.status === 200) {
                    history.push(backLink);
                    toast.success(t(`sauron.webhook.toast.success`));
                }
            })
            .catch(() => {
                toast.error(t(`sauron.webhook.toast.error`));
            });
    }, [form, backLink]);

    return (
        <>
            <WeezHeader
                rightComponent={[<Button key={0} primary inverted={true} label={t('common.cta.save')} onClick={handleSave} disabled={isSubmitDisabled} />]}
                title={t(`sauron.webhook.create.title`)}
                backLink={{
                    onClick: () => {
                        history.push(backLink);
                    }
                }}
            />
            <FrameCard title={t(`sauron.webhook.create.details.title`)} subtitle={t(`sauron.webhook.create.details.subtitle`)} separator>
                <WebHookDetails form={form} setForm={setForm} organizationId={organizationId} />
            </FrameCard>

            <FrameCard title={t(`sauron.webhook.options`)} separator>
                <WebHookOptions form={form} setForm={setForm} organizationId={organizationId} />
            </FrameCard>
        </>
    );
};
