import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Formatter } from '../../models';
import { usePermissions } from '../../models/user/permissions';
import { listSelection, requestCount } from '../../utils/utils';
import { WeezHeader, Button, Card, ContentCard, FilterSetController, Table, TableCellDropdown, ToastContext } from '@weezevent/nacre';

import { InfoTextTooltip, LoadingTable, PaginationDropDown, Paginator, SauronEmptyCard } from '../../components';

import css from './list.module.css';
import { FormatterActions, FormattersListActions } from './components/FormattersListActions';

export const FormattersList = ({ organizationId }) => {
    const toast = React.useContext(ToastContext);
    const user = usePermissions();
    const { t } = useTranslation();
    const history = useHistory();
    const [formattersIds, setFormattersIds] = React.useState([]);
    const [defaultPagination] = React.useState(() => ({ limit: 10, offset: 0 }));
    const [queryParams, setQueryParams] = React.useState({ search: '', ...defaultPagination });
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selectedFormatters, setSelectedFormatters] = React.useState([]);

    const [formatters, loadingFormatters, syncFormatters] = Formatter.useApiModel(
        {
            organizationId,
            cache: false,
            allow_cache: false,
            query: queryParams,
            onSuccess: response => {
                setFormattersIds(response.map(_formatters => _formatters.id));
            },
            onError: () => {
                toast.error(t('sauron.toasts.error'));
            }
        },
        [queryParams, organizationId]
    );
    const selectedItems = React.useMemo(() => {
        if (!formatters || loadingFormatters) {
            return [];
        }
        return formatters.filter(it => selectedFormatters.includes(it.pk));
    }, [formatters, loadingFormatters, selectedFormatters]);

    const formattersCount = React.useMemo(() => {
        return requestCount(loadingFormatters, formatters);
    }, [formatters, loadingFormatters]);

    let handleSearch = React.useCallback(
        (_, value) => {
            setQueryParams({ search: value, ...defaultPagination });
        },
        [defaultPagination]
    );

    const handlePage = React.useCallback(
        value => {
            setCurrentPage(value);
            setQueryParams({ ...queryParams, offset: queryParams.limit * value });
        },
        [queryParams]
    );
    const handleSelect = React.useCallback(
        formatter => {
            setSelectedFormatters(listSelection(selectedFormatters, formattersIds, formatter));
        },
        [selectedFormatters, formattersIds]
    );

    const handleBulkAction = React.useCallback(
        ({ formatter, action }) => {
            Formatter.executeBulkAction({
                type: action,
                organizationId,
                filters: {
                    id__in: selectedFormatters
                }
            }).then(response => {
                const _formatter = formatter || formatters.find(it => it.id === selectedFormatters[0]);
                let values = { count: selectedFormatters.length, name: _formatter?.name };
                toast.success(t(`sauron.formatter.actions.${action}.progress`, values));

                Formatter.pollBulkAction(response)
                    .then(rsp => {
                        if (rsp.status === 'success') {
                            values.count = rsp.summary.success;
                            toast.success(t(`sauron.formatter.actions.${action}.success`, { ...values }));
                        }
                    })
                    .catch(() => {
                        toast.error(t(`sauron.formatter.actions.${action}.error`, values));
                    })
                    .finally(() => {
                        syncFormatters();
                    });
            });
        },
        [organizationId, formatters, syncFormatters, selectedFormatters]
    );

    const handleAction = React.useCallback(
        (formatter, action) => {
            return formatter[action]({ organizationId })
                .then(() => {
                    toast.success(t('sauron.formatter.toast.delete.success'));
                    syncFormatters();
                })
                .catch(err => {
                    console.error('Cannot delete formatter', err);
                    toast.error(t('sauron.formatter.toast.delete.error'));
                });
        },
        [organizationId, syncFormatters]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[
                    user.canManageFormatters(organizationId) && (
                        <Button
                            key={0}
                            primary
                            inverted={true}
                            label={t('sauron.formatter.create-button')}
                            onClick={() => history.push(organizationId ? `/organizations/O${organizationId}/tools/formatters/new` : `/tools/formatters/new`)}
                        />
                    )
                ]}
                title={t('common.formatters')}
                backLink={{
                    onClick: () => {
                        history.push(`/organizations`);
                    }
                }}
            >
                {t('sauron.formatters.list')}
            </WeezHeader>
            <Card>
                <ContentCard>
                    <FilterSetController
                        total={formattersCount}
                        labelTotal={t('common.formatters', { count: formattersCount })}
                        onChange={() => {}}
                        search={{
                            onChange: (_, value) => {
                                handleSearch(_, value);
                            },
                            handleReset: () => {
                                handleSearch('');
                            },
                            placeholder: t('sauron.formatter.search'),
                            value: queryParams.search
                        }}
                    />
                    <PaginationDropDown
                        title={t('sauron.formatters.formatter_action', { count: selectedFormatters.length })}
                        tooltip={t('sauron.formatters.no_action_tooltip')}
                        label={'sauron.formatters.page_size'}
                        selected={selectedFormatters}
                        count={formattersCount}
                        disabled={!selectedFormatters}
                        limit={queryParams.limit}
                        pageSizes={[10, 50, 100]}
                        handlePage={handlePage}
                        items={<FormattersListActions handleAction={handleBulkAction} selectedItems={selectedItems} />}
                        onChange={value => {
                            setQueryParams({ ...queryParams, limit: value, offset: 0 });
                            setCurrentPage(0);
                        }}
                    />
                </ContentCard>

                <Table useNew selectable onSelectAll={() => handleSelect()} className={css['formatters-table']}>
                    <Table.THead>
                        <Table.Tr isHeader active={formattersIds.length > 0 && formattersIds.every(id => selectedFormatters.includes(id))}>
                            <th className={css['formatters-table-medium']}>{t(`sauron.formatter.name`)}</th>
                            {!organizationId && <th className={css['formatters-table-medium']}>{t(`sauron.formatter.organizer`)}</th>}
                            <th className={css['formatters-table-medium']}>{t(`sauron.formatter.values`)}</th>
                            <th className={css['formatters-table-medium']}>{t(`sauron.formatter.filters`)}</th>
                            <th className={css['formatters-table-medium']}>{t(`sauron.formatter.template`)}</th>
                            {user.canManageFormatters(organizationId) && <th className={css['formatters-table-medium']}>{t(`sauron.formatter.action`)}</th>}
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!loadingFormatters &&
                            formatters?.length > 0 &&
                            formatters?.map(_formatter => (
                                <FormatterItem
                                    key={_formatter.id}
                                    formatter={_formatter}
                                    selected={selectedFormatters}
                                    onSelect={handleSelect}
                                    organizationId={organizationId}
                                    onOpen={() => {}}
                                    handleAction={handleAction}
                                />
                            ))}
                    </Table.TBody>
                </Table>

                <LoadingTable loading={loadingFormatters} height="30vh" />
                {!loadingFormatters &&
                    (formatters?.length > 0 ? (
                        <Paginator currentPage={currentPage} onPageChange={handlePage} pageSize={queryParams.limit} totalCount={formattersCount} />
                    ) : (
                        <SauronEmptyCard title={t('sauron.formatters.empty-card-title')} subTitle={t('sauron.formatters.empty-card-subtitle')} />
                    ))}
            </Card>
        </>
    );
};

const FormatterItem = ({ formatter, selected, organizationId, onSelect, handleAction }) => {
    const history = useHistory();
    const user = usePermissions();
    const { name, organization_id, values, filter, template } = formatter;
    const detailsLink = React.useMemo(() => {
        if (!organizationId) {
            return `/tools/formatters/${formatter.pk}`;
        }
        return `/organizations/O${organizationId}/tools/formatters/${formatter.pk}`;
    }, [formatter, organizationId]);

    return (
        <Table.Tr onSelected={() => onSelect(formatter.id)} active={selected.includes(formatter.id)} onClick={() => history.push(detailsLink)}>
            <Table.Td>{name}</Table.Td>
            {!organizationId && <Table.Td>{`O ${organization_id}`}</Table.Td>}
            <Table.Td className={css['url-wrap']}>{<InfoTextTooltip title={values || '-'} />}</Table.Td>
            <Table.Td className={css['url-wrap']}>{<InfoTextTooltip title={filter || '-'} />}</Table.Td>
            <Table.Td className={css['url-wrap']}>{<InfoTextTooltip title={template || '-'} />}</Table.Td>

            {user.canManageFormatters(organizationId) && (
                <TableCellDropdown>
                    <FormatterActions formatter={formatter} handleAction={handleAction} actions={['delete']} />
                </TableCellDropdown>
            )}
        </Table.Tr>
    );
};
