import { ActionModel } from './ActionModel';

const FORMATTER_ACTIONS = [
    {
        action: 'bulk_delete',
        label: 'delete',
        method: 'delete',
        endpoint: 'actions',
        allow_filters: true,
        default_filters: {},
        required_fields: [],
        is_bulk_action: true,
        delete_item: true,
        disabled: { condition: true, bypass_item: false, key: 'is_linked_to_webhook' }
    }
];

export class Formatter extends ActionModel {
    constructor({ ...args }) {
        super({ ...args });
        for (const [key, value] of Object.entries(args)) {
            this[key] = value;
        }
    }

    fields() {
        return {
            name: '',
            id: { primaryKey: true, readOnly: true }
        };
    }

    parseDefinition() {
        const definition = this.object?.definition;

        if (!definition) {
            return {};
        }

        try {
            return JSON.parse(definition);
        } catch (jsonError) {
            try {
                const correctedDefinition = definition.replace(/'/g, '"');
                return JSON.parse(correctedDefinition);
            } catch (secondError) {
                console.error('Failed to parse JSON definition:', {
                    originalError: jsonError,
                    secondAttemptError: secondError,
                    definition
                });
                return {};
            }
        }
    }

    get values() {
        return JSON.stringify(this.parseDefinition()['jmespath_values']) || '';
    }

    get filter() {
        return this.parseDefinition()['jmespath_filter'] || '';
    }

    get template() {
        return this.parseDefinition()['template'] || '';
    }

    toOption() {
        return {
            text: this.name,
            value: this.id,
            key: this.id
        };
    }

    static listUrl(params) {
        if (!params.organizationId) {
            return `/webhook/formatter`;
        }
        return `/webhook/organizations/${params.organizationId}/formatter`;
    }

    static getModelActions() {
        return FORMATTER_ACTIONS;
    }
}
